@import "./spacing";
@import "./colors";

@mixin row {
  display: flex;
  flex-direction: row;
}

@mixin column {
  display: flex;
  flex-direction: column;
}

@mixin horizontalSpacing($spacing: $spacing-m) {
  & > :not(:last-child) {
    margin-right: $spacing;
  }
}

@mixin verticalSpacing($spacing: $spacing-m) {
  & > :not(:last-child) {
    margin-bottom: $spacing;
  }
}

@mixin rowWithSpacing($spacing: $spacing-m) {
  @include row;
  @include horizontalSpacing($spacing);
}

@mixin columnWithSpacing($spacing: $spacing-m) {
  @include column;
  @include verticalSpacing($spacing);
}

@mixin page {
  @include columnWithSpacing;
  background-color: $color-athens-gray;
  flex: 1 1 auto;
}

@mixin rowWithWrap($spacing: $spacing-m) {
  $half-spacing: $spacing / 2;

  @include row;
  flex-wrap: wrap;
  margin: -$half-spacing;

  & > * {
    margin: $half-spacing;
  }
}
