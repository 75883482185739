@import "../../../../styles/spacing";

.versionInfo {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-right-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 11px;
  padding: $spacing-xs $spacing-s;
  cursor: pointer;
}
