@import "../../../../styles/colors";

@mixin spinnerElement {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.spinner {
  @include spinnerElement;
  $spinnerSize: 4px;
  $borderSize: 1.1em;
  $semiTransparentBorder: $borderSize solid rgba(255, 255, 255, 0.5);

  margin: 15px;
  font-size: $spinnerSize;
  position: relative;
  text-indent: -9999em;
  border-top: $semiTransparentBorder;
  border-right: $semiTransparentBorder;
  border-bottom: $semiTransparentBorder;
  border-left: $borderSize solid $color-san-marino;
  transform: translateZ(0);
  animation: singleRotation 1.1s infinite linear;
  flex-shrink: 0;

  &:after {
    @include spinnerElement;
  }
}

@keyframes singleRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
