@import "styles/colors";
@import "styles/reset";
@import "styles/typography";
@import "styles/layout";

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  @include column;
  height: 100vh;

  & > div {
    @include column;
    flex: 1;
  }
}
