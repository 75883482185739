$color-puerto-rico: #49c5b1;
$color-azure: #385e9d;
$color-san-marino: #4f74b1;
$color-sunset-orange: #f9423a;
$color-fuel-yellow: #f39e2e;
$color-astra: #faf6b9;
$color-shark: #25282a;
$color-shuttle-gray: #5a6268;
$color-athens-gray: #f2f4f6;
$color-heather: #b5bccb;
$color-white: #ffffff;
$color-black: #000000;
$color-transparent-black: #00000088;
