@import "./colors";

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
span,
label {
  font-family: Roboto, sans-serif;
  color: $color-shark;
  line-height: normal;
}

p,
a,
button,
span,
label {
  font-size: 1em;
}

@mixin trimWithEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
